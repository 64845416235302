import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const UserMenu = ({ user, closeMenu }) => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout } = useAuth0();
  const goToPage = (page) => {
    closeMenu();
    navigate(`/${page}`);
  };
  return (
    <Container>
      {user && (
        <Row onClick={() => goToPage('dashboard')} clickable>
          Dashboard
        </Row>
      )}
      {user && (
        <Row
          clickable
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          Log out
        </Row>
      )}
      {!user && (
        <Row
          clickable
          onClick={() =>
            loginWithRedirect({
              redirect_uri: 'http://127.0.0.1:3000',
            })
          }
        >
          Log in
        </Row>
      )}
      {/* {!user && (
        <Row onClick={() => goToPage("register")} clickable>
          Register
        </Row>
      )} */}
    </Container>
  );
};

export default UserMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 300;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  justify-content: center;
`;
